<template>
  <div class="bar">
    <div class="items">
      <i class="el-icon-edit-outline" @click="edit">编辑</i>
      <i class="el-icon-delete" @click="del">删除</i>
    </div>
    <div class="total">
    <!--   <div class="total-box">
        <i class="el-icon-arrow-left" @click="prevObj"></i>
        <span> {{index}} / {{total}} </span>
        <i class="el-icon-arrow-right" @click="nextObj"></i>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'OperationItem',
  props: {
    currentIndex: {
      type: Number,
      required: true,
    },
    totalSize: {
      type: Number,
      required: true,
    },
    dataObj: {
    	type: Object,
    	required: false
    }
  },
  data() {
    return {
      currentObject: {},
      index: this.currentIndex,
      total: this.totalSize
    }
  },
  methods: {
  	edit(data) {
  		this.$emit("edit", data)
  	},
  	del(data) {
  		this.$emit("del", data)
  	},
  	prevObj(data) {
  		if(this.index > 1)
  			this.index -= 1
  			this.$emit("onPrevObj", this.index)
  	},
  	nextObj(data) {
  		if(this.index < this.total)
  			this.index += 1
  			this.$emit("onNextObj", this.currentIndex)
  	}
  }
}

</script>

<style lang="scss" scoped>
.bar {
  width: 100%;
  height: 40px;
  border-top: solid 1px #DFE4ED;
  border-bottom: solid 1px #DFE4ED;
  display: flex;
  justify-content: space-between;
  justify-content: center;
}

.items {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.items>i {
  margin-right: 20px;
  color: #1890ff;
  cursor:pointer;
}

.total {
  width: 20%;
  height: 100%;
}

.total-box {
  color: #1890ff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

:deep .el-icon-edit-outline:before {
  margin-right:5px!important;
}

:deep .el-icon-delete:before {
  margin-right:5px!important;
}
</style>