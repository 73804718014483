// import request from '@/utils/request'

import http from '@/common/utils/http'


/** 获取维保单 */
export function getElevatorMaintenancePage(data) {
  return http({
    url: `maintenancePlatform/getMaintenanceOrderPage`,
    method: 'post',
    data
  })
}

export function getMaintenanceDetailById(data) {
  return http({
    url: `maintenancePlatform/getMaintenanceDetailById?id=${data}`,
    method: 'post',
  })
}





// 创建电梯维护保养
// export function createElevatorMaintenance(data) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/create',
//     method: 'post',
//     data: data
//   })
// }

// // 更新电梯维护保养
// export function updateElevatorMaintenance(data) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/update',
//     method: 'put',
//     data: data
//   })
// }

// // 删除电梯维护保养
// export function deleteElevatorMaintenance(id) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/delete?id=' + id,
//     method: 'delete'
//   })
// }

// // 获得电梯维护保养
// export function getElevatorMaintenance(id) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/get?id=' + id,
//     method: 'get'
//   })
// }

// // 获得电梯维护保养分页
// export function getElevatorMaintenancePage(query) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/page',
//     method: 'get',
//     params: query
//   })
// }

// // 维保流程发起接口
// export function maintenanceOrderInitiated(data) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/maintenanceOrderInitiated',
//     method: 'post',
//     params: data
//   })
// }

// // 维保流程通过接口
// export function maintenanceOrderApprove(data) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/maintenanceOrderApprove',
//     method: 'post',
//     data: data
//   })
// }

// // 导出电梯维护保养 Excel
// export function exportElevatorMaintenanceExcel(query) {
//   return request({
//     url: '/shmashine-maintenance/elevator-maintenance/export-excel',
//     method: 'get',
//     params: query,
//     responseType: 'blob'
//   })
// }

