<template>
  <el-dialog title="图片预览" append-to-body :visible.sync="dialogShow" v-loading="loading" class="imagePreviewDialog" top="10vh" width="680px" :before-close="handleClose" @open="handleOpen">
    <img :src="item.picUrl" style="width:100%">
  </el-dialog>
</template>
<script>

export default {
  name: 'imagePreviewDialog',
  props: {
   item: {
      type: Object,
      default: {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      dialogShow: this.show,
      loading: false,
    }
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('update:show', false)
    },
    handleOpen() {
    },
    getInfo() {
      this.loading = true
      getMaintenanceDetail({
        workOrderId: this.item.workOrderId
      }).then(({ data }) => {
        this.loading = false
        this.detail = data
        Object.keys(this.form).forEach(keyName => {
          this.form[keyName] = data[keyName]
        })
        if (data.completeStatus == 0) {
          this.getUsers()
        }
        this.getMaintenanceItems()
      })
    },
    getUsers() {
      findMaintainerInfo({
        elevatorId: this.detail.elevatorId
      }).then(({ data }) => {
        this.userList = data.rows
        if (!data.rows.length) {
          return
        }
        // if (!this.form.dealEmployeeId) {
        //   this.form.dealEmployeeId = this.userList[0].maintainerId
        // }
      })
    }
  },
  watch: {
    show(val) {
      this.dialogShow = val
    }
  }
}

</script>
<style lang="scss" scoped>

</style>
