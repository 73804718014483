<template>
  <div class="detail-wrapper">
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="保养详情" name="elevator-maintaince">
        <MaintenanceDetail ref="info" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { Loading } from 'element-ui';
import Info from '@/pages/maintenance/order/components/info'
import MaintenanceDetail from '@/pages/maintenance/components/detail'
import OperationItem from '@/pages/components/mx-ui-components/mx-operationItem'
// import FaultRecord from '@/pages/common/components/faultRecord'
// import RepairRecord from '@/pages/common/components/repairRecord'
export default {
  name: 'OrderDetail',
  components: {
    Info,
    MaintenanceDetail,
    // FaultRecord,
    // RepairRecord,
    OperationItem
  },
  data() {
    return {
      activeTab: 'elevator-maintaince',
      dataId: null,
    }
  },
  methods: {
    /**
     * 根据数据更新
     */
    updateBy(data) {

    },
    /**
     * 根据参数请求获取数据
     */
    getBy(params) {
      this.dataId = params.id
      this.$refs.info.getBy(params)
    },
    onSubmit() {
      this.$emit('afterSubmit', this.dataId)
    },
  }
}

</script>
<style lang="scss" scoped>
.data-menu-wrapper {
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #ebecee;
  margin-bottom: 30px;
}

.operate-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
