<template>
  <div>
    <div class="data-wrapper">
      <div class="data-content">
        <div class="widget-wrapper">
          <div class="line-wrapper" v-for="(item, index) in dataList">
            <MXInfoParser :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
// import { getDictDatas, DICT_TYPE } from '@/utils/dict'
// import { decodeFields } from "@/utils/formGenerator";
import { getElevatorMaintenance, deleteElevatorMaintenance, maintenanceOrderApprove } from "@/api/maintenance/maintenance";
import store from "@/store";
import { datasInstance } from '@/pages/maintenance/order/datas'
// import FormParser from '@/components/FormParser/FormParser'
import MXInfoParser from '@/pages/components/mx-ui-components/mx-info-parser'
export default {
  name: 'workOrderInfo',
  components: {
    // MXInfoParser,
    // FormParser
  },
  data() {
    return {
      dataList: [], //小部件数据源集合
      dataId: null,

      detail: null,

      formList: [],

      // 流程表单详情
      detailForm: {
        fields: []
      },
      processInstance: {},

      runningTasks: [],
      tasks: [],
      auditForms: [],
      taskDefinitionKey: '',

      bpmParams: {
        id: '',
        longitude: ''
      }
    }
  },
  mounted() {},
  methods: {
    /**
     * 根据数据更新
     */
    updateBy(data) {

    },
    /**
     * 根据参数请求获取数据
     */
    getBy(params) {
      getElevatorMaintenance(params.id).then((res) => {
        this.detail = res.data
        this.dataList = datasInstance().parseDetailResponse(this.defaultDataList(), this.detail)
      })
    },

    delete(id, callback) {
      deleteElevatorMaintenance(id).then((res) => {
        if (callback) callback(true)
      }).catch((err) => {
        if (callback) callback(false)
      })
    },
    defaultDataList() {
      return [{
          'key': 'maintenanceCode',
          'label': '工单编号',
          'ui': 'mx-text',
        },
        {
          'key': 'stationName',
          'label': '维保站',
          'ui': 'mx-text',
        },
        {
          'key': 'projectName',
          'label': '项目',
          'ui': 'mx-text',
        },
        {
          'key': 'elevatorName',
          'label': '电梯名称',
          'ui': 'mx-text',
        },
        {
          'key': 'deviceCateogry',
          'label': '设备品种',
          'ui': 'mx-text',
        },
        {
          'key': 'deviceModel',
          'label': '设备型号',
          'ui': 'mx-text',
        },
        {
          'key': 'leader',
          'label': '负责人',
          'ui': 'mx-text',
        },
        {
          'key': 'installAddress',
          'label': '安装地点',
          'ui': 'mx-text',
        }
      ]
    }
  }
}

</script>
<style lang="scss" scoped>
.header-wrapper {
  height: 55px;
}

.widget-wrapper {}

.line-wrapper {
  padding: 16px 0 8px 0;
}

</style>
