<template>
    <div style="height: 100%; overflow-y: auto;">
        <el-tabs v-model="activeTab">
            <el-tab-pane label="维保单" name="1">
                <el-card class="box-card">
                    <div class="box-title">
                        基本信息
                    </div>
                    <MXDoubleInfo :items="basicInfoItems" />
                </el-card>
                <el-card class="box-card">
                    <div class="box-title">
                        位置信息
                    </div>
                    <div style="padding: 10px 0">
                        <MXLocGeocoder title="签到位置" :locInfo="signInLocInfo" />
                        <MXLocGeocoder title="签退位置" :locInfo="signOutLocInfo" />
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div class="box-title">
                        其它信息
                    </div>
                    <div style="padding: 10px 0">
                        <MXInput label="发现的问题及处理方式" :value="form.processingMethod?form.processingMethod:'无'" :disabled="true" />
                        <MXInput label="需要客户处理的事项" :value="form.mattersDealt?form.mattersDealt:'无'" :disabled="true" />
                        <MXInput label="配件使用" :value="form.accessoryUse?form.accessoryUse:'无'" :disabled="true" />
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane :label="`保养项(${maintenanceItems.length})`" name="3">
                <div class="box-card" v-for="(v, k) in maintenanceGroupItems">
                    <div class="box-title bg-dark">
                        {{v.title}}
                    </div>
                    <div class="box-item flex space-between" v-for="it in v.items">
                        <span style="flex:1;">【{{it.seqNo}}】{{it.item}} - {{it.content}}</span> <span :class="!(it.checked) ? 'item-status-unfinished':''">{{ it.checked ? '完成': '未完成'}}</span>
                    </div>
                    <div class="box-img" v-if="v.prvImgs && v.preImages.length">
                        <div>保养前照片</div>
                        <div class="image-wrapper flex flex-wrap">
                            <div class="block" v-for="fit in v.preImages">
                                <div style="cursor:pointer;">
                                    <el-image @click="onPreview(fit.url)" style="width: 100px; height: 100px" :src="fit.url" fit="cover"></el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-img" v-if="v.afterImgs && v.afterImages.length">
                        <div>保养后照片</div>
                        <div class="image-wrapper flex flex-wrap">
                            <div class="block" v-for="fit in v.afterImages">
                                <div style="cursor:pointer;">
                                    <el-image @click="onPreview(fit.url)" style="width: 100px; height: 100px" :src="fit.url" fit="cover"></el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <ImagePreviewDialog :show.sync="previewDialogShow" :item="previewItem"></ImagePreviewDialog>
    </div>
</template>
<script>
import store from "@/store";
import { mapGetters } from 'vuex'
import Moment from 'moment'
// import { getDictDatas, DICT_TYPE } from '@/utils/dict'
import { getDate } from "@/utils/dateUtils";
import { getMaintenanceDetailById } from "@/api/maintenance/maintenance";
import ImagePreviewDialog from '@/pages/maintenance/components/imagePreviewDialog'

import MXDoubleInfo from '@/pages/components/mx-biz-components/mx-double-info'
import MXInput from '@/pages/components/mx-ui-components/mx-input'
import MXLocGeocoder from '@/pages/components/mx-biz-components/mx-loc-geocoder'

import { getDictValue } from '@/utils/constants';
export default {
    name: 'execute',
    props: ['formData', 'showOperate'],
    components: {
        ImagePreviewDialog,
        MXDoubleInfo,
        MXInput,
        MXLocGeocoder
    },
    data() {
        return {
            dialogShow: this.show,
            loading: false,
            appoveBtnLoading: false,
            activeTab: '1',
            form: {
                workOrderId: null,
                shouldCompleteDate: '', // 应完成日期
                startMaintenanceTime: '', // 开始保养时间
                endMaintenanceTime: '', // 结束保养时间
                maintenanceOrderType: null, // 保养工单类型（1:半月保 2:季保1 3:半年保 4:季保2 5:年保）
                dealEmployeeId: '', // 具体处理人员id（工单状态为已完成时的人员信息）
                issues: '', // 主要问题
                issueHandling: '', // 主要问题处理情况
                // finishWorkOrder: false // 是否要工单完成
                fittingInfo: '', // 更换配件
                // finishWorkOrder: false // 是否要工单完成
                itemIds: [], // 维保细则,
                signInlocAddr: '',
                signOutlocAddr: ''
            },
            processInstance: {},
            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 100,
                elevatorCategory: null,
                type: null,
                item: null,
                content: null,
                location: null,
                createTime: [],
                status: null,
            },
            list: [],
            groupList: {},
            preImgList: [],
            afterImgList: [],
            signatureImgList: [],
            reportImgList: [],

            previewItem: null,
            previewDialogShow: false,

            rules: {},
            datePicker: {
                disabledDate: (time) => {
                    if (this.detail.minShouldCompleteDate && this.detail.maxShouldCompleteDate) {
                        return time.getTime() < new Moment(this.detail.minShouldCompleteDate) || time.getTime() > new Moment(this.detail.maxShouldCompleteDate)
                    } else {
                        return false
                    }
                }
            },
            maintenanceItems: [],

            maintenancePicUrls: {
                "preMotorRoom": [],
                "afterMotorRoom": [],
                "preCarRoof": [],
                "afterCarRoof": [],
                "preCarRoom": null,
                "afterCarRoom": [],
                "preFloorDoor": [],
                "afterFloorDoor": [],
                "preFloorStation": [],
                "afterFloorStation": [],
                "preBottomHole": [],
                "afterBottomHole": []
            },

            maintenanceGroupItems: [],
            // 审批记录
            tasksLoad: true,
            tasks: [],
            runningTasks: [],
            reason: '',


            basicInfoItems: [],
            signInLocInfo: {},
            signOutLocInfo: {},
        }
    },
    computed: {
        ...mapGetters({})
    },
    mounted() {
        // this.uploadUrl = this.getUploadUrl(this.item.workOrderId)
        this.activeTab = '1'
        this.maintenanceGroupItems = this.defaultMaintenanceGroupItems()
        // this.getMaintenanceItems()
    },
    methods: {
        getBy(params) {
            this.getDetailById(params.id)
        },
        getDetailById(orderId) {
            getMaintenanceDetailById(orderId).then((res) => {
                this.form = res.info.maintenanceDetail
                // this.maintenanceItems = res.info.maintenanceItems

                this.basicInfoItems = this.parseBaseInfoItems(res.info.maintenanceDetail)
                let locInfo = this.parseLocationAddress(res.info.maintenanceDetail)
                this.signInLocInfo = {
                    lon: locInfo.signInLon,
                    lat: locInfo.signInLat
                }
                this.signOutLocInfo = {
                    lon: locInfo.signOutLon,
                    lat: locInfo.signOutLat
                }
                if (res.info.maintenanceDetail.contentItems) {
                    this.maintenanceItems = JSON.parse(res.info.maintenanceDetail.contentItems)
                } else {
                    this.maintenanceItems = []
                }

                this.parseMaintenanceItems(this.maintenanceItems, res.info.maintenancePicUrls)
            })
        },
        parseMaintenanceItems(itemsList, imgObj) {
            this.maintenanceGroupItems.forEach((groupItem) => {
                groupItem.items = itemsList.filter((item) => {
                    return item.location == groupItem.location
                })
            })

            Object.keys(this.maintenancePicUrls).forEach((key) => {
                this.maintenancePicUrls[key] = imgObj[key]
            })
        },

        parseLocationAddress(data) {
            return {
                signInLon: data.signInLongitude,
                signInLat: data.signInLatitude,
                signOutLon: data.signOutLongitude,
                signOutLat: data.signOutLatitude,
            }
        },
        parseSignInLocationAddress(lon, lat) {
            if (lon && lat) {
                var point = new BMap.Point(lon, lat)
                let gc = new BMap.Geocoder()
                gc.getLocation(point, rs => {
                    this.form.signInlocAddr = rs.address
                    this.$forceUpdate();
                })
            }
        },
        parseSignOutLocationAddress(lon, lat) {
            if (lon && lat) {
                var point = new BMap.Point(lon, lat)
                let gc = new BMap.Geocoder()
                gc.getLocation(point, rs => {
                    this.form.signOutlocAddr = rs.address
                    this.$forceUpdate();
                })
            }
        },

        parseBaseInfoItems(data) {
            let list = [{
                    'label': '工单号',
                    'value': data.maintenanceCode
                },
                {
                    'label': '小区名称',
                    'value': data.communityName ? data.communityName : '--'
                }, {
                    'label': '电梯类型',
                    'value': this.getDictValue('elevator', data.elevatorType)
                }, {
                    'label': '电梯编号',
                    'value': data.elevatorCode
                }, {
                    'label': '注册码',
                    'value': data.registrationCode
                }, {
                    'label': '保养等级',
                    'value': this.getDictValue('maintenance', data.maintenanceType)
                }, {
                    'label': '安装地址',
                    'value': data.installAddress
                },
                {
                    'label': '第一维保人',
                    'value': data.maintainPrincipalPerson
                },
                {
                    'label': '第二维保人',
                    'value': data.maintainSubordinatePerson
                },
                {
                    'label': '安全管理员',
                    'value': data.propertySafeMan
                },
                {
                    'label': '计划保养时间',
                    'value': this.parseTime(data.maintenancePlannedTime)
                }, {
                    'label': '实际保养时间',
                    'value': this.parseTime(data.signInTime)
                },
            ]
            return list

        },


        defaultMaintenanceGroupItems() {
            return [{
                    location: 1,
                    title: '位置-机房',
                    items: [],
                    preImages: this.maintenancePicUrls.preMotorRoom,
                    afterImages: this.maintenancePicUrls.afterMotorRoom,
                },
                {
                    location: 2,
                    title: '位置-轿顶',
                    items: [],
                    preImages: this.maintenancePicUrls.preCarRoof,
                    afterImages: this.maintenancePicUrls.afterCarRoof,
                },
                {
                    location: 3,
                    title: '位置-轿厢',
                    items: [],
                    preImages: this.maintenancePicUrls.preCarRoom,
                    afterImages: this.maintenancePicUrls.afterCarRoom,
                },
                {
                    location: 6,
                    title: '层站',
                    items: [],
                    preImages: this.maintenancePicUrls.preFloorStation,
                    afterImages: this.maintenancePicUrls.afterFloorStation,
                },

                {
                    location: 5,
                    title: '底坑',
                    items: [],
                    preImages: this.maintenancePicUrls.preBottomHole,
                    afterImages: this.maintenancePicUrls.afterBottomHole,
                },
            ]
        },

        checkSelectable(val) {
            if (this.detail.completeStatus == 1) return false
            return !val.exclude
        },
        handleSelectionChange(val) {
            this.form.itemIds = val.map(v => v.id)
        },
        handleSwitchChange(val) {
            val.exclude = !val.exclude
            this.$refs.table.toggleRowSelection(val, false);
        },
        onAudit() {
            let params = this.buildBpmParams({ workOrderId: this.form.id }, this
                .runningTasks[0].id)
            this.appoveBtnLoading = true
            maintenanceOrderApprove(params).then((res) => {
                this.appoveBtnLoading = false
                location.reload();
            }).catch((err) => {
                this.appoveBtnLoading = false
            })
        },
        onPreview(url) {
            this.previewItem = {
                picUrl: url
            }
            this.previewDialogShow = true
        },
        buildBpmParams(params, taskId) {
            params.taskId = taskId
            params.id = params.workOrderId
            if (params.maintainceItems) {
                params.maintainceItems.forEach((item) => {
                    item.status = item.checked ? 1 : 0
                })
            }
            params.reason = params.reason ? params.reason : '无'
            let newParams = JSON.parse(JSON.stringify(params))
            if (newParams.signature)
                newParams.imgList.push(newParams.signature)
            return newParams
        },
        getTimelineItemIcon(item) {
            if (item.result === 1) {
                return 'el-icon-time';
            }
            if (item.result === 2) {
                return 'el-icon-check';
            }
            if (item.result === 3) {
                return 'el-icon-close';
            }
            if (item.result === 4) {
                return 'el-icon-remove-outline';
            }
            return '';
        },
        getTimelineItemType(item) {
            if (item.result === 1) {
                return 'primary';
            }
            if (item.result === 2) {
                return 'success';
            }
            if (item.result === 3) {
                return 'danger';
            }
            if (item.result === 4) {
                return 'info';
            }
            return '';
        },

        getDateStar(ms) {
            return getDate(ms);
        },

        getDictValue(type, key) {
            return getDictValue(type, key)
        }
    },
    watch: {
        show(val) {
            this.dialogShow = val
        }
    }
}
</script>
<style lang="scss" scoped>
.workOrderDialog {
    ::v-deep {
        .el-dialog__body {
            padding: 0 20px 20px;
        }
    }
}

.form {
    ::v-deep {
        .el-input {
            width: 200px;
        }

        .el-textarea {
            width: 420px;
        }
    }

}

.block {
    margin-right: 10px;
}

.box-card:not(first-of-type) {
    margin-top: 22px;
    width: 100%;
    padding: 0px !important;
}

.box-card:last-of-type {
    margin-bottom: 22px;
}

.box-title {
    font-size: 14px;
    font-weight: 700;
    color: #333333;
}

.box-content {
    clear: both;
    font-size: 13px;
    padding-top: 14px;
}

.box-content:last-of-type {
    overflow: hidden;
    margin-top: 14px;
}

.box-content-left {
    float: left;
}

.box-content-left>span {
    padding-left: 4px;
}

.box-content-left:after {
    clear: both;
}

.box-content-right {
    text-align: left;
    float: right;
}

.box-content-right>span {
    padding-left: 4px;
}

.box-title-span {
    float: right;
    font-size: 12px;
}

.bg-dark {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    background-color: #f5f6f8;
}

.box-item {
    font-size: 12px;
    padding: 8px 20px;
    border-bottom: 1px solid #F3F3F3;
}


.item-status-unfinished {
    color: red;
}

.box-img>div {
    margin-top: 10px;
    padding-left: 20px;
    font-size: 9px;
    color: #666666;
}

.operate-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>