<template>
  <div>
    <div class="data-wrapper">
      <div class="data-content">
        <div class="widget-wrapper">
          <MXElevatorSelector @selectElevator="onSelectElevator" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getElevatorMaintenance, createElevatorMaintenance, updateElevatorMaintenance,maintenanceOrderInitiated } from '@/api/maintenance/maintenance'
import MXElevatorSelector from '@/pages/components/mx-biz-components/mx-elevator-selector'
// import { datasInstance } from '@/pages/elevator/datas'
export default {
  name: 'MaintenanceOrderUpdate',
  components: {
    MXElevatorSelector
  },
  data() {
    return {
      dataId: null,
      dataList: [], //小部件数据源集合

      selectElevator: null,
    }
  },
  mounted() {

  },
  methods: {
    /**
     * 根据数据更新
     */
    updateBy(data) {

    },
    /**
     * 根据参数请求获取数据
     */
    getBy(params) {
      this.dataId = params.id
      getElevatorMaintenance(params.id).then((res) => {
        let data = res.data
        this.dataList = datasInstance().parseDetailResponse(this.defaultDataList(), data)
      })
    },
    submit(callback) {
      if (this.dataId) {
        updateElevatorMaintenance(this.buildParams(this.dataList)).then((res => {
          if (callback) callback(true)
        })).catch((err) => {
          if (callback) callback(false)
        })
      } else {
        if (this.selectElevator) {
          console.log(this.selectElevator)
          maintenanceOrderInitiated({
            id:this.selectElevator.id
          }).then((res => {
            if (callback) callback(true)
          })).catch((err) => {
            if (callback) callback(false)
          })
        } else {

        }

      }
    },
    onSelectElevator(elevator) {
      this.selectElevator = elevator
    },
    buildParams(dataList) {
      let params = {}
      dataList.forEach((item) => {
        params[item.key] = item.submitValue ? item.submitValue : item.value
      })
      return params
    }
  }
}

</script>
<style lang="scss" scoped>
.line-wrapper {
  width: 50%;
}

</style>
